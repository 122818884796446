import React from 'react'
import BackButtonImg from '../images/back.png'
import { Link } from 'react-router-dom'
import '../components/styles/BackButton.css'

class BackButton extends React.Component {

  render(){
    const {id, active} = this.props

    return(
      <React.Fragment>
        {active === "1" ?
          <Link to={`/exercise/${id}`}>
            <button type="button" className="Fitness-Back-btn">
              <img src={ BackButtonImg } className="Fitness-Back" alt='Volver' title="Volver" />
            </button>
          </Link>
        :
          <Link to="#">
            <button type="button" className="Fitness-Back-btn">
              <img src={ BackButtonImg } className="Fitness-Back Fitness-Back-disabled" alt='Volver' title="Volver" />
            </button>
          </Link>
        }
      </React.Fragment>
    )
  }
}

export default BackButton