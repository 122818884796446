import React from 'react'
import EditButtonImg from '../images/edit.png'
import { Link } from 'react-router-dom'
import '../components/styles/EditButton.css'

const EditButton = ({id}) => (
  <Link to={`/exercise/edit/${id}`}>
    <button type="button" className="Fitness-Edit-btn">
      <img src={ EditButtonImg } className="Fitness-Edit" alt='Editar' title="Editar" />
    </button>
  </Link>
)

export default EditButton