import React from 'react'
import HomeButtonImg from '../images/home.png'
import { Link } from 'react-router-dom'
import '../components/styles/HomeButton.css'

const HomeButton = () => (
  <Link to="/exercise">
    <button type="button" className="Fitness-Home-btn">
      <img src={ HomeButtonImg } className="Fitness-Home" alt='Home' title="Home" />
    </button>
  </Link>
)

export default HomeButton