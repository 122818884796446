import React from 'react'
import AddButtonImg from '../images/add.png'
import { Link } from 'react-router-dom'
import '../components/styles/AddButton.css'

const AddButton = () => (
  <Link to="/exercise/new">
    <button type="button" className="Fitness-Add-btn">
      <img src={ AddButtonImg } className="Fitness-Add" alt='Nuevo Ejercicio' title="Nuevo Ejercicio" />
    </button>
  </Link>
)

export default AddButton