import React from 'react'
import ExerciseForm from '../components/ExerciseForm'
import CardNew from '../components/CardNew'
import '../components/styles/ExerciseNew.css'
import Loading from '../components/Loading'
import FatalError from './500'
import HomeButton from '../components/HomeButton'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CopyRight from '../components/CopyRight'

class ExerciseNew extends React.Component {

  state = {
    form: {
      title: '',
      description: '',
      img: '',
      leftColor: '',
      rightColor: ''
    },
    loading: false,
    error: null
  }

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSubmit = async (e) => {
    this.setState({
      loading: true
    })
    e.preventDefault()
    try {
      let config = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.state.form)
      }
      let res = await fetch('https://proyectfitness-api.soriacarlos.com/api/exercise', config)
      // eslint-disable-next-line
      let json = () => {res.json()}

      this.setState({
        loading: false
      })

      const MySwal = withReactContent(Swal)

      MySwal.fire({
        position: 'center',
        icon: 'success',
        title: '¡ Registro Correcto !',
        showConfirmButton: false,
        timer: 1500
        
      }).then(() => {
        this.props.history.push('/exercise')
      })

    } catch (error) {
      this.setState({
        loading: false,
        error
      })
    }
  }

  render(){
    if(this.state.loading){
      return <Loading />
    }
    if(this.state.error){
      return <FatalError />
    }
    return(
      <React.Fragment>

        <div className="ExerciseNew_Lateral_Spaces row">

        <div className="col-sm ExerciseNew_Card_Space">
          <CardNew
            {...this.state.form}
          />
        </div>

        <div className="col-sm ExerciseNew_Card_Space">
          <ExerciseForm 
            onChange = {this.handleChange}
            onSubmit = {this.handleSubmit}
            form = {this.state.form}
          />
        </div>

        </div>

        <HomeButton />

        <CopyRight />
        
      </React.Fragment>
    )
  }
}

export default ExerciseNew