import React from 'react'
import './styles/Loading.css'

const Loading = () => {
  return(
    <React.Fragment>
       <div className="loader">Loading...</div>
    </React.Fragment>
  )
}

export default Loading