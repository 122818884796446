import React from 'react'
import './styles/Card.css'
import CirclesImg from '../images/circles.png'
import EmptyImg from '../images/empty.png'


class CardNew extends React.Component {
  render(){

    const { title, description, img, leftColor, rightColor } = this.props

      return (
        <React.Fragment>
          
                <div className="card mx-auto Fitness-Card" style={{ 
                  backgroundImage: `url(${CirclesImg}), linear-gradient(to right, ${leftColor || '#A74CF2'}, ${rightColor || '#617BFB'})`
                }}>
                    <div className="card-body">
                      <div className="row center">
                        <div className="col-6">
                          <img src={img || EmptyImg} className="float-right" alt={title} style={{ width: '100px' }} />
                        </div>
                        <div className="col-6 Fitness-Card-Info">
                          <h1>{title}</h1>
                          <p>{description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                
             
        </React.Fragment>
      )
    
  }
}

export default CardNew