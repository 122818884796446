import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Exercises from '../pages/Exercises'
import ExerciseNew from '../pages/ExerciseNew'
import NotFound from '../pages/404'
import ExercisePage from '../pages/ExercisePage'
import ExerciseEdit from '../pages/ExerciseEdit'

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={ Exercises } />
      <Route exact path="/exercise" component={ Exercises } />
      <Route exact path="/exercise/new" component={ ExerciseNew } />
      <Route exact path="/exercise/edit/:id" component={ ExerciseEdit } />
      <Route exact path="/exercise/:id" component={ ExercisePage } />
      <Route component={ NotFound } />
    </Switch>
  </BrowserRouter>
)

export default App