import React from 'react'
import TrashButtonImg from '../images/trash.png'
import { Link } from 'react-router-dom'
import '../components/styles/TrashButton.css'

class TrashButton extends React.Component {
  render(){

    const { onClick } = this.props

    return(
      <React.Fragment>
        <Link to="#">
          <button type="button" onClick={onClick} className="Fitness-Trash-btn">
            <img src={ TrashButtonImg } className="Fitness-Trash" alt='Eliminar' title="Eliminar" />
          </button>
        </Link>
      </React.Fragment>
    )
  }
}

export default TrashButton