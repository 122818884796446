import React from 'react'
import Card from './Card'
import FatalError from '../pages/500'

class ExerciseList extends React.Component {

  render(){
    const {exerciseData} = this.props
    if(!exerciseData){
      return <FatalError />
    }
    return (
      <React.Fragment>
        {exerciseData.map( (exercises) => {
          return(
            <Card 
              id = {exercises.id}
              key = {exercises.id}
              title = {exercises.title}
              description = {exercises.description}
              img = {exercises.img}
              leftColor = {exercises.leftColor}
              rightColor = {exercises.rightColor}
              active = {exercises.active}
            />
          )
        })}        
      </React.Fragment>
    )
  }
}

export default ExerciseList