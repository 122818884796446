import React from 'react'
import './styles/Welcome.css'

const Welcome = ({username}) => (
  <React.Fragment>
    <div className="container">
      <div className="Fitness-User-Info">
        <h1>Welcome {username}!</h1>
        <p>Ley's workout to get someone agins!</p>
      </div>
    </div>
  </React.Fragment>
)

export default Welcome