import React from 'react'
import './styles/Card.css'
import CirclesImg from '../images/circles.png'
import EmptyImg from '../images/empty.png'
import { Link } from 'react-router-dom'

class Card extends React.Component {
  render(){

    const { id, title, description, img, leftColor, rightColor, active } = this.props

      return (
        <React.Fragment>
          <div>
            {active === "1" ?
                <Link to={`/exercise/${id}`} className="Fitness-Card-Link">
                <div className="card mx-auto Fitness-Card" style={{ 
                  backgroundImage: `url(${CirclesImg}), linear-gradient(to right, ${leftColor || '#A74CF2'}, ${rightColor || '#617BFB'})`
                }}>
                    <div className="card-body">
                      <div className="row center">
                        <div className="col-6">
                          <img src={img || EmptyImg} className="float-right" alt={title} style={{ width: '100px' }} />
                        </div>
                        <div className="col-6 Fitness-Card-Info">
                          <h1>{title}</h1>
                          <p>{description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              :
              <Link to={`/exercise/edit/${id}`} className="Fitness-Card-Link">
                <div className="card mx-auto Fitness-Card" style={{ 
                  backgroundImage: `url(${CirclesImg}), linear-gradient(to right, #232526, #414345)`
                }}>
                    <div className="card-body">
                      <div className="row center">
                        <div className="col-6">
                          <img src={img || EmptyImg} className="Fitness-Card-Disabled float-right" alt={title} style={{ width: '100px' }} />
                        </div>
                        <div className="col-6 Fitness-Card-Info">
                          <h1>{title}</h1>
                          <p>{description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
            }
          </div>
        </React.Fragment>
      )
    
  }
}

export default Card