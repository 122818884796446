import React from 'react'
import ExerciseFormEdit from '../components/ExerciseFormEdit'
import CardEdit from '../components/CardEdit'
import '../components/styles/ExerciseEdit.css'
import Loading from '../components/Loading'
import FatalError from './500'
import BackButton from '../components/BackButton'
import HomeButton from '../components/HomeButton'
import TrashButton from '../components/TrashButton'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CopyRight from '../components/CopyRight'

class ExerciseEdit extends React.Component {

  state = {
    form: {
      id: '',
      title: '',
      description: '',
      img: '',
      leftColor: '',
      rightColor: '',
      active: ''
    },
    loading: false,
    error: null
  }

  async componentDidMount(){
    // Capturamos ID de URL
    const { id } = this.props.match.params;
    await this.fetchElement(id)
    
    this.setState({
      id
    })
  }

  fetchElement = async (id) => {
    try {
      let res = await fetch(`https://proyectfitness-api.soriacarlos.com/api/exercise/${id}`)
      let data = await res.json()
      console.log({Mensaje: 'Datos recibidos correctamente'});
      this.setState({
        form: data
      })
      
    } catch (error) {
      console.log({Mensaje: 'Error al recibir los datos'});
      this.setState({
        error
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSubmit = async (e) => {
    
    this.setState({
      loading: true
    })
    console.log({Mensaje: 'Ingreso a la función handleSubmit'});
    e.preventDefault()
    try {
      let config = {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.form)
      }
      
      console.log({Mensaje: 'Ingreso a TRY'});
      let res = await fetch(`https://proyectfitness-api.soriacarlos.com/api/exercise/edit/${this.state.id}`, config)
      // console.log({Mensaje: 'Realizo mi petición al API'});
      // eslint-disable-next-line
      let json = () => {res.json()} 
      
           
      console.log({Mensaje: 'Se envío la información a editar'});

      // let res = await fetch(`https://proyectfitness-api.soriacarlos.com/api/exercise/edit/${this.state.id}`, config)
      // .then( res => console.log(res()))

    //   fetch("http://localhost/Api/classConexion.php")
    // .then( response => response.text())
    // .then( text => console.log(text));
      
      this.setState({
        loading: false
      })

      const MySwal = withReactContent(Swal)

        MySwal.fire({
          position: 'center',
          icon: 'success',
          title: '¡ Execelente !',
          showConfirmButton: false,
          timer: 1500
          
        }).then(() => {
          if(this.state.form.active === '1'){
            this.props.history.push(`/exercise/${this.state.id}`)
          }else{
            this.props.history.push(`/exercise`)
          }
        })

    } catch (error) {
      console.log({Mensaje: 'NO se envío la información a editar'});
      console.log(error);
      this.setState({
        loading: false,
        error
      })
    }
  }

  handleDelete = async () => {
    const id = this.state.id
    if(id > 3){
    try {
      
        let config = {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.state.form)
        }
        let res = await fetch(`https://proyectfitness-api.soriacarlos.com/api/exercise/delete/${id}`, config)
        // eslint-disable-next-line
        let json = () => {res.json()}
        
        this.setState({
          loading: false
        })
          this.props.history.push('/exercise/')
        
    } catch (error) {
      this.setState({
        loading: false,
        error
      })
    }
  }
  }
  
  hancleDeleteClicked = () => {
    const MySwal = withReactContent (Swal)

    MySwal.fire({
        title: '¡Estás por eliminar esto!',
        text: "Si lo eliminas no habrá vuelta atrás",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          MySwal.fire(
            'Correcto!',
            'El Ejercicio fue eliminado.',
            'success'
          )
          this.handleDelete()
        }
      })
  }


  render(){
    if(this.state.loading){
      return <Loading />
    }
    if(this.state.error){
      return <FatalError />
    }

    return(
      <React.Fragment>

        <div className="Fitness-Page-Buttons">
          <HomeButton />
          <BackButton 
            {...this.state.form}
          />
          <TrashButton 
            onClick={this.hancleDeleteClicked}
          />
        </div>

        <div className="ExerciseNew_Lateral_Spaces row">

        <div className="col-sm ExerciseNew_Card_Space">
          <CardEdit 
            {...this.state.form}
          />
        </div>

        <div className="col-sm ExerciseNew_Card_Space">
          <ExerciseFormEdit 
          {...this.state.form}
            onChange = {this.handleChange}
            onSubmit = {this.handleSubmit}
            form = {this.state.form}
            
          />
        </div>

        </div>
        
        <div className="Fitness-Page-Buttons">
          <HomeButton />
          <BackButton 
            {...this.state.form}
          />
          <TrashButton 
            onClick={this.hancleDeleteClicked}
          />
        </div>

        <CopyRight />
        
      </React.Fragment>
    )
  }
}

export default ExerciseEdit