import React from 'react'

class ExerciseForm extends React.Component {
  render(){

    const { onChange, onSubmit, form } = this.props

    return(
      <React.Fragment>
        <div className="container">
          <form onSubmit={onSubmit}>

            <div className="form-group">

                {form.active === "1" ?
                <select
                  name="active"
                  className="form-control"
                  onChange={onChange}
                  value="1"
                >
                  <option value="1">ON</option>
                  <option value="0">OFF</option>
                </select>
                :
                <select
                  name="active"
                  className="form-control"
                  onChange={onChange}
                  value="0"
                >
                  <option value="1">ON</option>
                  <option value="0">OFF</option>
                </select>
                }
            </div>

            <div className="form-group">
            {form.active === "1" ?
              <input 
              type="text"
              className="form-control"
              placeholder="Title"
              name="title"
              onChange={onChange}
              value={form.title}
              />
            :
              <input 
              type="text"
              className="form-control"
              placeholder="Título"
              name="title"
              onChange={onChange}
              value={form.title}
              disabled
              />
            }
            </div>

            <div className="form-group">
            {form.active === "1" ?
              <input 
              type="text"
              className="form-control"
              placeholder="Descripción"
              name="description"
              onChange={onChange}
              value={form.description}
              />
            :
              <input 
              type="text"
              className="form-control"
              placeholder="Description"
              name="description"
              onChange={onChange}
              value={form.description}
              disabled
              />
            }
            </div>

            <div className="form-group">
            {form.active === "1" ?
              <input 
              type="text"
              className="form-control"
              placeholder="http://"
              name="img"
              onChange={onChange}
              value={form.img}
              />
            :
              <input 
              type="text"
              className="form-control"
              placeholder="http://"
              name="img"
              onChange={onChange}
              value={form.img}
              disabled
              />
            }
            </div>

            <div className="row">
              <div className="col">
                {form.active === "1" ?
                <input 
                type="text"
                className="form-control"
                placeholder="#A74CF2"
                name="leftColor"
                onChange={onChange}
                value={form.leftColor}
                />
              :
                <input 
                type="text"
                className="form-control"
                placeholder="#A74CF2"
                name="leftColor"
                onChange={onChange}
                value={form.leftColor}
                disabled
                />
              }
              </div>

              <div className="col">
              {form.active === "1" ?
                <input 
                type="text"
                className="form-control"
                placeholder="#617BFB"
                name="rightColor"
                onChange={onChange}
                value={form.rightColor}
                />
              :
                <input 
                type="text"
                className="form-control"
                placeholder="#617BFB"
                name="rightColor"
                onChange={onChange}
                value={form.rightColor}
                disabled
                />
              }
              </div>
            </div>
              <hr />
             
              <button type="submit" className="btn btn-primary float-right">
                Enviar
              </button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default ExerciseForm