import React from 'react'

class ExerciseForm extends React.Component {
  render(){

    const { onChange, onSubmit, form } = this.props

    return(
      <React.Fragment>
        <div className="container">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <input 
                type="text"
                className="form-control"
                placeholder="Title"
                name="title"
                onChange={onChange}
                value={form.title}
              />
            </div>
            <div className="form-group">
              <input 
                type="text"
                className="form-control"
                placeholder="Description"
                name="description"
                onChange={onChange}
                value={form.description}
              />
            </div>
            <div className="form-group">
              <input 
                type="text"
                className="form-control"
                placeholder="Img"
                name="img"
                onChange={onChange}
                value={form.img}
              />
            </div>
            <div className="row">
              <div className="col">
                <input 
                  type="text"
                  className="form-control"
                  placeholder="LeftColor"
                  name="leftColor"
                  onChange={onChange}
                  value={form.leftColor}
                />
              </div>

              <div className="col">
                <input 
                  type="text"
                  className="form-control"
                  placeholder="RightColor"
                  name="rightColor"
                  onChange={onChange}
                  value={form.rightColor}
                />
              </div>
            </div>
            <hr />
            <button type="submit" className="btn btn-primary float-right">
              Enviar
            </button>
            <input type="hidden" name="active" value={form.active = 1} />
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default ExerciseForm