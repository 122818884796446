import React from 'react'
import './styles/CopyRight.css'

const CopyRight = () => (
  <React.Fragment>
    <div className="Fitness-Copy">
      {
      // eslint-disable-next-line
      }
      <p>Copyright &copy; 2020 - <a href="https://soriacarlos.com" target="_blank" rel="noreferrer">Carlos Soria</a></p>
    </div>
  </React.Fragment>
)

export default CopyRight